import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ModalCareers from "../components/Modal/ModalBuydown";
import Layout from "../components/Layout";
import FooterPromoBar from "../components/Navigation/FooterPromo";
import styled from "@emotion/styled";
import tw from "twin.macro";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedRateOptions from "../components/Repeating/RelatedRateOptions";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import FAQs from "../components/Repeating/FAQs";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

const StyledContent = styled.div`
  #page-footer {
    ${tw`mb-[56px]`}
  }
`;
const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
    const faqs = [
      {
        question: "How is this different from paying points?",
        answer: (
          <>
            <p className="">
              When you pay points, you’re reducing the interest rate for the
              life of the loan. And it’s typically a smaller percentage drop,
              like less than 1%.
            </p>
            <p className="mb-0">
              With a temporary interest rate buydown, the higher percentage drop
              you get at the beginning can significantly lower your monthly
              payment out of the gate. This can benefit those who expect income
              to rise over time but need a lower payment to start with or buyers
              who want extra money to make improvements.
            </p>
          </>
        ),
      },
      {
        question: "Who pays for the buydown?",
        answer: (
          <>
            <p>
              It can be anyone involved in the transaction that sees a benefit
              in paying for the buydown. For example, a builder of new
              construction might want to incentivize buyers, or a seller of an
              existing home might want to sway buyers in a competitive market.
            </p>
          </>
        ),
      },
      {
        question: "How much does it cost?",
        answer: (
          <>
            <p>
              The cost of a temporary interest rate buydown depends on the size
              of the loan and the buydown type (the interest rate deduction
              amount and duration). It can also differ between lenders. The
              upside is that the savings typically far outweigh the cost.
            </p>
          </>
        ),
      },
      {
        question:
          "What’s better—a buydown, paying points, or a bigger down payment?",
        answer: (
          <>
            <p className="">
              If you’re a borrower who plans to stay in the home for just a few
              years, or you’ll be refinancing once interest rates go down, then
              a rate buydown is a great choice. It’s also a smart option if you
              expect a higher income in the future and need a lower monthly
              mortgage payment until you get there. You benefit from the lower
              interest rate when you need it most in all three instances.
            </p>
            <p className="mb-0">
              If you plan on living in the home long-term, you might consider
              paying points to give you a lower interest rate for the life of
              the loan or making a larger down payment to reduce the overall
              loan amount.
            </p>
          </>
        ),
      },
    ];

  return (
    <StyledContent>
      <Layout>
        <SearchEngineOptimization
          title="Temporary Interest Rate Buydown | 2-1 Buydown | Right Start"
          description="Get your mortgage interest rate improved with a temporary interest rate buydown! At Right Start, we offer the best buydown programs to suit your needs!"
          openGraphImage={data.openGraphImage.publicURL}
          twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
        />

        <section className="relative overflow-hidden py-20 md:py-28">
          <div className="absolute left-0 top-0 h-full w-full ">
            <StaticImage
              src="../images/3.0 Mortgage Rate Options/Hero.jpg"
              loading="eager"
              className="h-full"
            />
          </div>
          <div className="container">
            <div className="relative max-w-[640px]">
              <h1 className="text-white">
                Save Money with a Temporary Interest Rate Buydown
              </h1>
              <p className="mb-0 text-white">
                Homebuyers—get a lower interest rate at the start of your loan
                and take advantage of the benefits that come with it. Right
                Start Mortgage has the buydown option to make it happen.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
          <div className="container">
            <h2 className="pb-20">
              What It Means to ‘Buy Down’ a Mortgage Loan
            </h2>
            <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
              {/* START OF SCROLLNAV */}
              <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
                <li>
                  <AnchorLink
                    to={url + "#section-1"}
                    title="What is it?"
                    stripHash
                  />
                </li>
                <li>
                  <AnchorLink
                    to={url + "#section-2"}
                    title="How it works"
                    stripHash
                  />
                </li>
                <li>
                  <AnchorLink
                    to={url + "#section-3"}
                    title="Timeframe"
                    stripHash
                  />
                </li>

                <li>
                  <AnchorLink
                    to={url + "#section-4"}
                    title="Examples"
                    stripHash
                  />
                </li>
              </ScrollNavigation>
              {/* END OF SCROLLNAV */}
              {/* START OF SCROLL CONTENT */}
              <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
                <div
                  id="section-1"
                  className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
                >
                  <h2 className="heading-four">
                    You Start With a Lower Interest Rate
                  </h2>

                  <p className="mb-0">
                    With a temporary interest rate buydown, you have a lower
                    interest rate at the beginning of your loan, which has some
                    great benefits. You’ll not only save on interest, but you’ll
                    have a lower monthly mortgage payment at the start of your
                    term.
                  </p>
                </div>

                <div
                  id="section-2"
                  className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
                >
                  <h2 className="heading-four">You Save Money Right Away</h2>
                  <p className="mb-0">
                    A rate buydown is a good option for buyers in a market with
                    higher interest rates. It can get you in the door with a
                    better rate, plus lower your monthly mortgage payments at
                    the beginning of your loan. It’s also a great way to free up
                    extra funds that you can use to spruce up your new home.
                  </p>
                </div>

                <div
                  id="section-3"
                  className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
                >
                  <h2 className="heading-four">
                    It’s Essentially Prepaid Interest
                  </h2>
                  <p className="mb-0">
                    With a mortgage loan buydown, the buyer pays a lower
                    interest rate at the beginning of the loan. A seller,
                    builder, lender, or third party subsidizes that lower
                    interest rate by paying money into an escrow account.
                  </p>
                </div>

                <div
                  id="section-4"
                  className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
                >
                  <h2 className="heading-four">There Are Different Programs</h2>
                  <p className="mb-0">
                    The most common is a 2-1 buydown program that lowers the
                    interest rate by 2% for the first year, 1% for the second
                    year, and then you pay the full rate for year three through
                    the end of the term. There’s also a 1-0 program with a 1%
                    lower interest rate for the first year only.
                  </p>
                </div>
              </div>
              {/* END OF SCROLL CONTENT */}
            </div>
          </div>
        </section>
        <FAQs heading="FAQs" uniqueFaqs={faqs} />
        <FooterPromoBar className="mt-16" />
        <ModalCareers />
        <Testimonials />
        <ValueProps />
        <About />

        <RelatedRateOptions gridHeading={true} gridCols={true} />
        <CallToAction className="mb-20 md:mb-32" />
      </Layout>
    </StyledContent>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
