import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const FAQs = ({ className, heading, uniqueFaqs }) => {
  let faqs = "";

  uniqueFaqs
    ? (faqs = uniqueFaqs)
    : (faqs = [
        {
          question: "",
          answer: <></>,
        },
      ]);

  return (
    <section className={`mb-20 md:mb-32 ${className}`}>
      <div className="container">
        <header className="mb-10 md:mb-16">
          <h2>{heading || "FAQs"}</h2>
        </header>

        <Accordion
          allowZeroExpanded={true}
          className="border-t border-gray-200"
        >
          {faqs.map((faq, i) => {
            return (
              <div key={i}>
                <AccordionItem
                  uuid={i}
                  className="border-b border-gray-200 py-6 md:py-12"
                >
                  <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                    <AccordionItemState>
                      {(state) => {
                        return (
                          <>
                            <p className="mb-0 font-heading font-bold text-typography-heading md:text-xl">
                              {faq.question}
                            </p>

                            <i
                              className={`far ${
                                state.expanded ? "fa-minus" : "fa-plus"
                              } text-2xl text-secondary-500 md:text-4xl`}
                            ></i>
                          </>
                        );
                      }}
                    </AccordionItemState>
                  </AccordionItemButton>
                  <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                    {faq.answer}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
        </Accordion>
      </div>
    </section>
  );
};

export default FAQs;
