import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
const StyledModal = styled.div`
    #modal-buydown {
        &.is-open {
            ${tw`block`}
        }
        &[aria-hidden="false"] {
            .overlay {
                animation: mmfadeInBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        &[aria-hidden="false"] {
            .content-wrapper {
                animation: mmslideInBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        &[aria-hidden="true"] {
            .overlay {
                animation: mmfadeOutBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        &[aria-hidden="true"] {
            .content-wrapper {
                animation: mmslideOutBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        .content-wrapper,
        .overlay {
            will-change: transform;
        } 
    }
    @keyframes mmfadeInBottom {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    @keyframes mmfadeOutBottom {
        from { opacity: 1; }
        to { opacity: 0; }
    }

    @keyframes mmslideInBottom {
        from { transform: translateY(100%); }
        to { transform: translateY(0); }
    }

    @keyframes mmslideOutBottom {
        from { transform: translateY(0); }
        to { transform: translateY(100%); }
    }
}
`;
const FooterPromoBar = ({ navigationStyle }) => {
  return (
    <div className="modalBottom relative mt-16" data-modal-open="modal-buydown">
      <StyledModal>
        <div className="fixed -bottom-[5px]  z-40 w-full bg-primary-500 font-bold text-white">
          <div id="promo-bar" className=" py-6 text-center">
            <div className="container">
              <p className="text-[24px] m-0 h-[24px] p-0 leading-[24px]">
                Lock in my rates
              </p>
            </div>
          </div>
        </div>
      </StyledModal>
    </div>
  );
};
export default FooterPromoBar;
